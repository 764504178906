import clsx from 'clsx';

import style from './style.module.scss';

interface IQuestionTitleProps extends ICustomizable {
  children: React.ReactNode;
}

export const QuestionTitle = (props: IQuestionTitleProps) => {
  return <strong className={clsx(style['question-title'], props.className)}>{props.children}</strong>;
};

import { QuestionOptions } from './QuestionOptions';
import { QuestionRoot } from './QuestionRoot';
import { QuestionSubtitle } from './QuestionSubtitle';
import { QuestionTitle } from './QuestionTitle';

export const Question = {
  Root: QuestionRoot,
  Options: QuestionOptions,
  Subtitle: QuestionSubtitle,
  Title: QuestionTitle,
};

import clsx from 'clsx';
import { RadioInput } from '../RadioInput';
import style from './style.module.scss';

interface ISelectableOptionProps extends Omit<React.OptionHTMLAttributes<HTMLDivElement>, 'title'> {
  selected?: boolean;
  onSelectionChange?: (value: string) => void;
  className?: string;
  title: React.ReactNode;
  value: string;
  name?: string;
}

export const SelectableOption = (props: ISelectableOptionProps) => {
  const { title, value, name, selected, onSelectionChange, className = '', ...rest } = props;

  const handleKeySelection = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onSelectionChange?.(value);
    }
  };

  return (
    <div
      className={clsx(
        style['selectable-option__wrapper'],
        { [style['selectable-option__wrapper_checked']]: selected },
        className
      )}
      onClick={() => onSelectionChange?.(value)}
      onKeyDown={handleKeySelection}
      {...rest}
    >
      {title}
      <div className={style['selectable-option__radio-wrapper']}>
        <RadioInput small checked={selected} options={[{ value, view: '' }]} name={name} />
      </div>
    </div>
  );
};

import { FormBWizardActions } from './FormBWizardActions';
import { FormBWizardBackButton } from './FormBWizardBackButton';
import { FormBWizardContent } from './FormBWizardContent';
import { FormBWizardNextButton } from './FormBWizardNextButton';
import { FormBWizardRoot } from './FormBWizardRoot';
import { FormBWizardSubmitButton } from './FormBWizardSubmitButton';

export const FormBWizard = {
  Root: FormBWizardRoot,
  Actions: FormBWizardActions,
  BackButton: FormBWizardBackButton,
  Content: FormBWizardContent,
  NextButton: FormBWizardNextButton,
  SubmitButton: FormBWizardSubmitButton,
};

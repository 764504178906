import { t } from '@ping/helpers';
import { userIdSelector, useUserInformationStore } from '@ping/stores/userInformation.store';
import { Form, Question, SelectableOptionsList, TextField } from '@ping/uikit';
import { useContext, useEffect, useState } from 'react';

import { FormBWizardContext } from '../form-b-wizard.context';
import { UserIndustryType } from '@ping/api';

type IFormBWizardContentProps = ICustomizable;

export const FormBWizardContent = (props: IFormBWizardContentProps) => {
  const [isOtherIndustry, setIsOtherIndustry] = useState(false);
  const ctx = useContext(FormBWizardContext);
  const userId = useUserInformationStore(userIdSelector);

  const handleOptionsChange = selectedOption => {
    setIsOtherIndustry(selectedOption === UserIndustryType.Other);
  };

  useEffect(() => {
    if (ctx.otherIndustry && ctx.wizard.name === 'industry') {
      setIsOtherIndustry(true);
    }
  }, [ctx.otherIndustry, ctx.wizard.name]);

  return (
    <Form.Content className={props.className}>
      <input type='hidden' name='userId' value={userId} />

      <Question.Root>
        <Question.Title>{ctx.wizard.question}</Question.Title>
        <Question.Subtitle>{t('Financial regulations require us to ask this question.')}</Question.Subtitle>
        <Question.Options>
          <SelectableOptionsList
            options={ctx.wizard.options}
            defaultValue={ctx.defaultOption}
            name={ctx.wizard.name}
            onChange={handleOptionsChange}
          />
          {isOtherIndustry && <TextField placeholder={t('Industry')} name='otherIndustry' value={ctx.otherIndustry} />}
        </Question.Options>
      </Question.Root>
    </Form.Content>
  );
};

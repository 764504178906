import { FormContent } from './FormContent';
import { FormRoot } from './FormRoot';
import { FormSubmit } from './FormSubmit';

export type { IFormRootProps } from './FormRoot';

export const Form = {
  Root: FormRoot,
  Content: FormContent,
  Submit: FormSubmit,
};

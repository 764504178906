import { t } from '@ping/helpers';
import { Form } from '@ping/uikit';
import clsx from 'clsx';
import { useContext } from 'react';

import { FormBWizardContext } from '../form-b-wizard.context';
import style from './style.module.scss';

type IFormBWizardNextButtonProps = ICustomizable;

export const FormBWizardNextButton = (props: IFormBWizardNextButtonProps) => {
  const ctx = useContext(FormBWizardContext);

  return (
    <>
      {ctx.showNextButton && (
        <Form.Submit className={clsx(style['form-b-wizard-next'], props.className)} aria-label='next'>
          {t('Next')}
        </Form.Submit>
      )}
    </>
  );
};

import { clsx } from 'clsx';
import { useContext } from 'react';
import { mergeProps } from 'react-aria';

import { FormContext } from '../form.context';
import style from './style.module.scss';

interface IFormContentProps extends ICustomizable {
  children: React.ReactNode;
}

export const FormContent = (props: IFormContentProps) => {
  const ctx = useContext(FormContext);

  return (
    <form className={clsx(style['form-content'], props.className)} {...mergeProps(ctx.formProps, props)}>
      {props.children}
    </form>
  );
};

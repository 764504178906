import clsx from 'clsx';

import style from './style.module.scss';

interface IFormBWizardActionsProps extends ICustomizable {
  children: React.ReactNode;
}

export const FormBWizardActions = (props: IFormBWizardActionsProps) => (
  <footer className={clsx(style['form-b-wizard-action'], props.className)}>{props.children}</footer>
);

import {
  ComplianceStepType,
  useGetNextStepCompliance,
  useGetPreviousStepCompliance,
  useGetUserInfo,
  UserEmploymentStatusType,
  UserIndustryType,
  UserPrimarySourceType,
  UserPrimaryUseType,
  UserTotalValueType,
  useSetUserInfo,
} from '@ping/api';
import { userInformationStore } from '@ping/stores/userInformation.store';
import { Form, Toast } from '@ping/uikit';
import { t } from '@ping/helpers';
import { nop } from '@ping/utils';
import { useRef, useState } from 'react';

import { FormBWizardContext } from '../form-b-wizard.context';

import type { IFormRootProps } from '@ping/uikit';

interface IFormBWizardRootProps extends IFormRootProps {
  children: React.ReactNode;
  defaultStep: ComplianceStepType;
  onSubmit?: () => void;
}

const DEFAULT_PROPS: Readonly<Partial<IFormBWizardRootProps>> = {
  defaultStep: ComplianceStepType.PrimaryUse,
  onSubmit: nop,
};

// TODO: translate
const formB = new Map<ComplianceStepType, any>()
  .set('PrimaryUse', {
    name: 'primaryUse',
    question: '1. What will you primarily use Ping Exchange for?',
    options: [
      { value: UserPrimaryUseType.Investing, title: 'Investing' },
      { value: UserPrimaryUseType.TradingOnPingExchange, title: 'Trading on Ping Exchange' },
      { value: UserPrimaryUseType.TradingOnOtherExchanges, title: 'Trading on other exchanges' },
      { value: UserPrimaryUseType.OnlinePurchases, title: 'Online purchases' },
      { value: UserPrimaryUseType.PaymentToFriends, title: 'Payment to friends' },
      { value: UserPrimaryUseType.OnlinePayments, title: 'Online payments' },
      { value: UserPrimaryUseType.Business, title: 'Business' },
    ],
  })
  .set('PrimarySource', {
    name: 'primarySource',
    question: '2. What’s your primary source of funds?',
    options: [
      { value: UserPrimarySourceType.Occupation, title: 'Occupation' },
      { value: UserPrimarySourceType.Investments, title: 'Investments' },
      { value: UserPrimarySourceType.Savings, title: 'Savings' },
      { value: UserPrimarySourceType.Inheritance, title: 'Inheritance' },
      { value: UserPrimarySourceType.CreditLoan, title: 'Credit / Loan' },
      { value: UserPrimarySourceType.FamilyOrOthers, title: 'Family or others' },
      { value: UserPrimarySourceType.CryptocurrencyMining, title: 'Cryptocurrency mining' },
    ],
  })
  .set('EmploymentStatus', {
    name: 'employmentStatus',
    question: '3. What’s your employment status?',
    options: [
      { value: UserEmploymentStatusType.Employed, title: 'Employed' },
      { value: UserEmploymentStatusType.Unemployed, title: 'Unemployed' },
      { value: UserEmploymentStatusType.Retired, title: 'Retired' },
      { value: UserEmploymentStatusType.Student, title: 'Student' },
      { value: UserEmploymentStatusType.SelfEmployed, title: 'Self employed' },
    ],
  })
  .set('TotalValue', {
    name: 'totalValue',
    question:
      '4. What’s the total value - including digital assets - that you expect to transfer to your Ping Exchange account in the next 12 months?',
    options: [
      { value: UserTotalValueType.Under1000, title: 'Under $1,000' },
      { value: UserTotalValueType.Between1000And9999, title: '$1,000 - $9,999' },
      { value: UserTotalValueType.Between10000And99999, title: '$10,000 - $99,999' },
      { value: UserTotalValueType.Between100000And249999, title: '$100,000 - $249,999' },
      { value: UserTotalValueType.Between250000And999999, title: '$250,000 - $999,999' },
      { value: UserTotalValueType.Between1000000And2999999, title: '$1,000,000 - $2,999,999' },
      { value: UserTotalValueType.Between3000000And4999999, title: '$3,000,000 - $4,999,999' },
      { value: UserTotalValueType.Over5000000, title: 'Over $5,000,000' },
    ],
  })
  .set('Industry', {
    name: 'industry',
    question: '5. What industry do you work in?',
    options: [
      { value: UserIndustryType.Agriculture, title: 'Agriculture' },
      { value: UserIndustryType.ArtsAndMedia, title: 'Arts & Media' },
      { value: UserIndustryType.CasinosAndGaming, title: 'Casino & Gambling' },
      { value: UserIndustryType.Cryptocurrency, title: 'Cryptocurrency' },
      { value: UserIndustryType.Defense, title: 'Defense' },
      { value: UserIndustryType.Education, title: 'Education' },
      { value: UserIndustryType.EnergyOther, title: 'Energy (Other)' },
      { value: UserIndustryType.EnergyOilAndGas, title: 'Energy (Oil & Gas)' },
      { value: UserIndustryType.Other, title: 'Other' },
    ],
  });

export const FormBWizardRoot = (props: IFormBWizardRootProps) => {
  props = { ...DEFAULT_PROPS, ...props };

  const [step, setStep] = useState<ComplianceStepType>(
    // To ensure that step is always related to From B
    formB.has(props.defaultStep) ? props.defaultStep : DEFAULT_PROPS.defaultStep
  );
  //
  // NOTE: useGetNextStepCompliance and useGetPreviousStepCompliance
  //  - if we don't send the "step" we get the next step based on user data
  //  - if we send the "step" we get the next step based on the procedural flow
  const nextStep = useGetNextStepCompliance({ step });
  const previousStep = useGetPreviousStepCompliance(
    { step },
    {
      query: { enabled: step !== ComplianceStepType.PrimaryUse },
    }
  );

  const userInfo = useGetUserInfo();
  const wizard = formB.get(step);
  const ctxValue = {
    wizard,
    defaultOption: userInfo.data?.[wizard?.name] || wizard?.options.at(0).value,
    showBackButton: Boolean(previousStep.data),
    showNextButton: Boolean(nextStep.data) && formB.has(nextStep.data),
    showSubmitButton: !nextStep.isLoading && !formB.has(nextStep.data),
    otherIndustry: userInfo.data?.otherIndustry,
  };

  const userMutation = useSetUserInfo();
  const topOfFormRef = useRef<React.ElementRef<'div'>>();

  const validateOtherIndustry = (data: FormData) => {
    const formData = Object.fromEntries(data.entries());
    if (formData.industry && formData.industry === UserIndustryType.Other && !formData.otherIndustry) {
      Toast.error({ title: t('Please enter your industry name') });
      return true;
    }
    return false;
  };

  const updateUserInfo = async (data: FormData, action: 'submit' | 'next' | 'back') => {
    try {
      if (action === 'submit' && !validateOtherIndustry(data)) {
        await userMutation.mutateAsync({ data: Object.fromEntries(data.entries()) });
        props.onSubmit();
      }

      if (action === 'next' && !validateOtherIndustry(data)) {
        await userMutation.mutateAsync({ data: Object.fromEntries(data.entries()) });
        userInformationStore.setComplianceStep(nextStep.data);
        setStep(nextStep.data);
        nextStep.refetch();
      }

      if (action === 'back') {
        // userInformationStore.setComplianceStep(previousStep.data); // TODO: do we need this?
        setStep(previousStep.data);
        previousStep.refetch();
        userInfo.refetch();
      }
    } catch (error) {
      // NOTE: it'll be handled by global axios interceptor
    } finally {
      topOfFormRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Form.Root
      label={step}
      isInProgress={userMutation.isLoading || userInfo.isLoading || nextStep.isLoading || previousStep.isLoading}
      onSubmit={updateUserInfo}
    >
      <div
        role='none'
        aria-hidden='true'
        style={{ marginBlockStart: '-2rem', paddingBlockStart: '2rem' }}
        ref={topOfFormRef}
      />
      <FormBWizardContext.Provider value={ctxValue} key={step + ctxValue.defaultOption}>
        {props.children}
      </FormBWizardContext.Provider>
    </Form.Root>
  );
};

import { t } from '@ping/helpers';
import { Form } from '@ping/uikit';
import clsx from 'clsx';
import { useContext } from 'react';

import { FormBWizardContext } from '../form-b-wizard.context';
import style from './style.module.scss';

type IFormBWizardBackButtonProps = ICustomizable;

export const FormBWizardBackButton = (props: IFormBWizardBackButtonProps) => {
  const ctx = useContext(FormBWizardContext);

  return (
    <>
      {ctx.showBackButton && (
        <Form.Submit className={clsx(style['form-b-wizard-back'], props.className)} aria-label='back'>
          {t('Back')}
        </Form.Submit>
      )}
    </>
  );
};

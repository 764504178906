import { FormContext } from '../form.context';
import { useForm } from '../form.util';

import type { IFormParams } from '../form.util';

export interface IFormRootProps extends IFormParams {
  children: React.ReactNode;
}

export const FormRoot = (props: IFormRootProps) => {
  const { children, label, isInProgress, onSubmit } = props;
  const { formProps, submitProps } = useForm({ label, isInProgress, onSubmit });

  return <FormContext.Provider value={{ formProps, submitProps }}>{children}</FormContext.Provider>;
};

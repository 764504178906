import { useRef } from 'react';
import { useTextField } from 'react-aria';
import { FieldGroup } from '../FieldGroup';
import clsx from 'clsx';

import style from './style.module.scss';

import type IReactAria from 'react-aria';

export interface ITextFieldProps extends IReactAria.AriaTextFieldProps, ICustomizable {
  name?: string;
  /** A config to handle border-radius and spacing when it used inside ComboField component */
  magnet?: IMagnet;
}

export const TextField = (props: ITextFieldProps) => {
  const inputRef = useRef();
  const { labelProps, inputProps } = useTextField(props, inputRef);

  return (
    <FieldGroup className={clsx('grow', style['field-group'], props.className)}>
      <FieldGroup.Label {...labelProps}>{props.label}</FieldGroup.Label>

      <FieldGroup.Control magnet={props.magnet}>
        <input {...inputProps} name={props.name} ref={inputRef} />
      </FieldGroup.Control>
    </FieldGroup>
  );
};

import clsx from 'clsx';

import style from './style.module.scss';

interface IQuestionOptionsProps extends ICustomizable {
  children: React.ReactNode;
}

export const QuestionOptions = (props: IQuestionOptionsProps) => {
  return <div className={clsx(style['question-options'], props.className)}>{props.children}</div>;
};

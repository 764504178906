import { ButtonA11Y, IButtonA11YProps } from '@ping/uikit/ButtonA11Y';
import { clsx } from 'clsx';
import { useContext } from 'react';
import { mergeProps } from 'react-aria';

import { FormContext } from '../form.context';
import style from './style.module.scss';

interface IFormSubmitProps extends IButtonA11YProps {
  children: React.ReactNode;
}

export const FormSubmit = (props: IFormSubmitProps) => {
  const { className, children, ...buttonProps } = props;

  const ctx = useContext(FormContext);

  return (
    <ButtonA11Y className={clsx(style['form-submit'], className)} {...mergeProps(buttonProps, ctx.submitProps)}>
      {children}
    </ButtonA11Y>
  );
};

import clsx from 'clsx';
import { useState } from 'react';
import { SelectableOption } from '../SelectableOption';
import style from './style.module.scss';

type SelectableOption = {
  title: React.ReactNode;
  value: string;
};

interface ISelectableOptionsListProps {
  options: SelectableOption[];
  defaultValue?: string;
  onChange?: (value: string) => void;
  className?: string;
  name?: string;
}

export const SelectableOptionsList = ({
  options,
  onChange,
  className,
  defaultValue,
  name,
}: ISelectableOptionsListProps) => {
  const [selectedOption, setSelectedOption] = useState<string>(defaultValue);

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    onChange?.(value);
  };

  return (
    <div className={clsx(style['selectable-options-list__wrapper'], className)}>
      {options.map(option => (
        <SelectableOption
          key={option.value}
          tabIndex={0}
          title={option.title}
          value={option.value}
          selected={option.value === selectedOption}
          onSelectionChange={handleOptionChange}
          name={name}
        />
      ))}
    </div>
  );
};

SelectableOptionsList.SelectableOption = SelectableOption;

import clsx from 'clsx';

import style from './style.module.scss';

interface IQuestionSubtitleProps extends ICustomizable {
  children: React.ReactNode;
}

export const QuestionSubtitle = (props: IQuestionSubtitleProps) => {
  return <p className={clsx(style['question-subtitle'], props.className)}>{props.children}</p>;
};

import { t } from '@ping/helpers';
import { Form } from '@ping/uikit';
import clsx from 'clsx';
import { useContext } from 'react';

import { FormBWizardContext } from '../form-b-wizard.context';
import style from './style.module.scss';

interface IFormBWizardSubmitButtonProps extends ICustomizable {
  children?: React.ReactNode;
}

export const FormBWizardSubmitButton = (props: IFormBWizardSubmitButtonProps) => {
  const ctx = useContext(FormBWizardContext);

  return (
    <>
      {ctx.showSubmitButton && (
        <Form.Submit className={clsx(style['form-b-wizard-submit'], props.className)} aria-label='submit'>
          {props.children ?? t('Submit')}
        </Form.Submit>
      )}
    </>
  );
};

import React from 'react';

interface IFormBWizardContext {
  wizard: any;
  defaultOption: string;
  showBackButton: boolean;
  showNextButton: boolean;
  showSubmitButton: boolean;
  otherIndustry: string;
}

export const FormBWizardContext = React.createContext<IFormBWizardContext>({
  wizard: undefined,
  defaultOption: undefined,
  showBackButton: false,
  showNextButton: false,
  showSubmitButton: false,
  otherIndustry: undefined,
});

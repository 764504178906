import { useId } from 'react';

export interface IFormParams {
  label?: string;
  isInProgress?: boolean;

  /**
   * A callback function invoked when the form is submitted.
   *
   * @param data - The form data submitted.
   * @param submitter - An optional string indicating the submitter of the form via the `aria-label` attribute of the submit button.
   * If not provided, the submitter is considered undefined.
   *
   * Use Case: Differentiating between multiple submit buttons
   *
   * When you have a form with multiple submit buttons, the `submitter` parameter allows
   * you to identify which button was used to trigger the form submission. This is
   * useful for executing different actions based on the button clicked, such as
   * handling "Save," "Cancel," or "Delete" actions separately.
   *
   * Example usage:
   * ```tsx
   * const handleFormSubmit = (formData: FormData, submitter?: string) => {
   *  if (submitter === 'save') {
   *    // Handle submission by the "save" button.
   *    return;
   *  }
   *
   *  if (submitter === 'cancel') {
   *    // Handle submission by the "cancel" button.
   *    return;
   *  }
   *
   *  // Handle submission with an undefined submitter.
   * };
   * ```
   */
  onSubmit?: (data: FormData, submitter?: string) => void;
}

/**
 * It returns form and submit props for a React form component.
 * @param {IFormParams} params - The `params` parameter is an object that contains the following
 * properties:
 * - onSubmit: it runs on form submitting.
 */
export const useForm = (params: IFormParams) => {
  const formProps = {
    id: `${params.label ?? ''}${useId()}`,
    onSubmit: (ev: React.FormEvent<HTMLFormElement>) => {
      ev.preventDefault();

      const data = new FormData(ev.target as HTMLFormElement);
      const submitter = (ev.nativeEvent as SubmitEvent).submitter.getAttribute('aria-label') || '';
      params.onSubmit?.(data, submitter);
    },
    'aria-busy': params.isInProgress,
    'aria-label': params.label,
  };

  const submitProps = {
    form: formProps.id,
    type: 'submit',
    disabled: params.isInProgress,
    isDisabled: params.isInProgress,
  };

  return { formProps, submitProps };
};

import React from 'react';

import { useForm } from './form.util';

interface IFormContext {
  formProps: ReturnType<typeof useForm>['formProps'];
  submitProps: ReturnType<typeof useForm>['submitProps'];
}

export const FormContext = React.createContext<IFormContext>({
  formProps: undefined,
  submitProps: undefined,
});
